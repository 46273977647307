import { handleClickAndEnter } from "./handleClickAndEnter";
import { $ } from "../third-party-imports";

/**
 * Handle locate button on header - Mobile
 */

export function mobileHandleLocateOnHeader()
{
    $(".navWrapper .propertForm button").on("click", function ()
    {
        var inputElement = $('.navWrapper .propertForm').find('input');
        var validationErrorElement = $('.navWrapper .propertForm .invalid-feedback');
        handleClickAndEnter(inputElement, validationErrorElement);
    });
}
