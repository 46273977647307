export async function parsePhone(phone: string, country: string): Promise<string>
{
    const PhoneNumber = (await import("awesome-phonenumber")).default;
    let countries = new Map<string, string>([
        ["usa", "US"],
        ["us", "US"],
        ["united states", "US"],
        ["ca", "CA"],
        ["can", "CA"],
        ["canada", "CA"]
    ]);
        
    let countryCode = countries.get(country.toLocaleLowerCase()) ?? "US";

    const parsedPhone = new PhoneNumber(phone, countryCode);
    return parsedPhone.isValid() ? parsedPhone.getNumber("national") : phone;
}