import { $ } from "../third-party-imports";

function getCards(sortedGroupByStateObj: any, states: any[], idStrAppend: any)
{
    var cards = states.map((state, index) =>
    {
        var stateDbaList: any[] = sortedGroupByStateObj[state];

        var cardLiEle = stateDbaList.map((stateDbaItem) =>
        {
            var sub_li = $(
                '<li><a href="' +
                stateDbaItem.websiteUrl +
                '" target="_blank">' +
                stateDbaItem.doingBusinessAs +
                "</a></li>"
            );
            return sub_li;
        });

        var ul = $('<ul class="list-unstyled listSecondary"></ul>').append(
            cardLiEle
        );

        var ulString = ul.prop("outerHTML");
        index = index + idStrAppend;

        var card = '<div class="card">' +
            '<div class="card-header" id="heading-' +
            index +
            '">' +
            '<h5 class="mb-0">' +
            '<a class="collapsed" role="button" data-toggle="collapse" href="#collapse-' +
            index +
            '" aria-expanded="false" aria-controls="collapse-' +
            index +
            '">' +
            state +
            '<span class="circle_qa"></span>' +
            "</a>" +
            "</h5>" +
            "</div>" +
            '<div id="collapse-' +
            index +
            '" class="collapse" aria-labelledby="heading-' +
            index +
            '" style="">' +
            '<div class="card-body">' +
            ulString +
            "</div>" +
            "</div>" +
            "</div>";

        return card;
    });

    return cards;
}

export function locateAnOffice()
{
    $.ajax({
        type: "GET",
        contentType: "application/json",
        url: `https://api.nblytest.com/franchisegroup-api-develop/v1/public/concepts/rpm/franchiseWeblocations?apikey=${window.env.apiKey}`,
        dataType: "json",
        success: function (result: any)
        {
            var franchiseeAll = [];
            for (let item of result)
            {
                franchiseeAll.push(item.franchiseDetails[0]);
            }
            franchiseeAll = franchiseeAll.filter(function (element)
            {
                return element !== undefined;
            });

            var groupByStateObj = franchiseeAll.reduce(function (r, a)
            {
                r[a.state] = r[a.state] || [];
                r[a.state].push(a);
                return r;
            }, Object.create(null));

            var sortedGroupByStateObj = Object.keys(groupByStateObj)
                .sort()
                .reduce(function (acc: {[k: string]: string}, key)
                {
                    acc[key] = groupByStateObj[key];
                    return acc;
                }, {});

            var statesKeys = Object.keys(sortedGroupByStateObj);

            let aToMStates = statesKeys.filter((key) => /^[A-M]/.test(key));
            let ntoWStates = statesKeys.filter((key) => /^[N-W]/.test(key));

            let aToMStatesCards = getCards(sortedGroupByStateObj, aToMStates, "aToM");
            let nToWStatesCards = getCards(sortedGroupByStateObj, ntoWStates, "nToW");
            $("#nav-state-one").find(".faq_wrap").empty();
            $("#nav-state-one").find(".faq_wrap").append(aToMStatesCards.join(" "));

            $("#nav-state-two").find(".faq_wrap").empty();
            $("#nav-state-two").find(".faq_wrap").append(nToWStatesCards.join(" "));
        },
        error: function (xhr: any, status: any, error: any)
        {
            console.log(xhr, status, error);
        },
    });
}
