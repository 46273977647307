import { filterBlogFromList } from "../../blogarticle/util/filterBlogFromList";

import { $ } from "../../third-party-imports";

export function updateFooterLinks(allBlogs: any[])
{
    var allBlogs = allBlogs;
    var currentBlogIndex: number | null = null;
    $.each(allBlogs, function (index: any, blog: any)
    {
        if (filterBlogFromList(blog) === true)
        {
            currentBlogIndex = index;
        }
    });
    if (currentBlogIndex === null)
    {
        $($('.returnPageLink .backLinkArrow')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .backLinkArrow')[0]).addClass('disable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('disable-next-link');
    } else if (currentBlogIndex < 1 && currentBlogIndex < (allBlogs.length - 1))
    {   
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        try {nextBlogURL = new URL(nextBlogURL);}catch {};
        $($('.returnPageLink .backLinkArrow')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .backLinkArrow')[0]).addClass('disable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).attr("href", `${window.location.origin}${nextBlogURL?.pathname ? nextBlogURL?.pathname : nextBlogURL }`);
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('enable-next-link');
    } else if (currentBlogIndex >= 1 && currentBlogIndex < (allBlogs.length - 1))
    {
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        try {
            prevBlogURL = new URL(prevBlogURL);
            nextBlogURL = new URL(nextBlogURL);
        }catch{};
        $($('.returnPageLink .backLinkArrow')[0]).attr("href", `${window.location.origin}${prevBlogURL?.pathname ? prevBlogURL?.pathname : prevBlogURL}`);
        $($('.returnPageLink .backLinkArrow')[0]).addClass('enable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).attr("href", `${window.location.origin}${nextBlogURL?.pathname ? nextBlogURL?.pathname : nextBlogURL }`);
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('enable-next-link');
    } else if (currentBlogIndex > 1 && currentBlogIndex >= (allBlogs.length - 1))
    {
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        try {prevBlogURL = new URL(prevBlogURL);}catch{};
        $($('.returnPageLink .backLinkArrow')[0]).attr("href", `${window.location.origin}${prevBlogURL?.pathname ? prevBlogURL?.pathname : prevBlogURL}`);
        $($('.returnPageLink .backLinkArrow')[0]).addClass('enable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('disable-next-link');
    }
}
