import { handleClickAndEnter } from "./handleClickAndEnter";
import { $ } from "../third-party-imports";

/**
 * Handle locate button on Banner Image - Web & Mobile
 */

export function handleLocateOnBanner()
{
    $(".bannerHeroContainer .propertForm button").on("click", function ()
    {
        var inputElement = $('.bannerHeroContainer .propertForm').find('input');
        var validationErrorElement = $('.bannerHeroContainer .propertForm .invalid-feedback');
        handleClickAndEnter(inputElement, validationErrorElement);
    });
}
