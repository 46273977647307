import { handleClickAndEnter } from "./handleClickAndEnter";
import { $ } from "../third-party-imports";

/**
 * Handle locate button on header - Web
 */

export function handleLocateOnHeader()
{
    $(".locateForm button").on("click", function ()
    {
        var inputElement = $('.locateForm').find('input');
        var validationErrorElement = $('.locateForm .invalid-feedback');
        handleClickAndEnter(inputElement, validationErrorElement);
    });
}
