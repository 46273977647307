import { $ } from "../third-party-imports";

export function clearContactUsForm(form: any)
{
    $(form).find("#interest_property").val("");
    $(form).find("#inputContactEmail").val("");
    $(form).find("#inputfname").val("");
    $(form).find("#inputlname").val("");
    $(form).find("#inputContactZip").val("");
    $(form).find("#inputdaytime").val("");
    $(form).find("#inputComments").val("");

    //form builder fields
    $(form).find("#inputEmailAddress").val("");
    $(form).find("#inputfirstname").val("");
    $(form).find("#inputlastname").val("");
    $(form).find("#inputZipCode").val("");
    $(form).find("#inputPhone").val("");
    $(form).find("#inputComment").val("");
}
