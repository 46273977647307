import { domLoaded } from '../util/domLoaded';
import {initBlogArticle} from './util/initBlogArticle'

domLoaded(async () => 
{
    const articleSection = document.querySelector(".blogArticleSection");
    if (articleSection)
    {
        //await (await import("./util/initBlogArticle")).initBlogArticle();
        initBlogArticle();
    }
});