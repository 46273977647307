// import $ from "jquery";

// window.$ = $;
// window.jQuery = $;

// import "jquery-validation";

// export { $ };
let $ : any;
(function($s) {
$ = $s;
})(jQuery);
export { $ };