import { clearContactUsForm } from "./clearContactUsForm";
import { $ } from "../third-party-imports";

export function handleContactUsForm()
{ 
    const contactFormApi = `${window.env.contactUsBaseEndpoint}?apikey=${window.env.apiKey}`;
    const formsList = $(".contact_us_form");
    
    for (var i = 0; i < formsList.length; i++)
    {
        const formElem = $(formsList[i]);
        formElem.validate({
            rules: {
                inputContactEmail: {
                    required: true,
                    emailValidation: true,
                },
                inputContactZip: {
                    required: true,
                    zipValidation: true,
                },
                inputdaytime: {
                    required: true,
                    phoneValidation: true,
                },
                interest_property: {
                    required: true,
                },
            },
            messages: {
                inputContactEmail: "That doesn’t look like a valid email.",
                inputContactZip: "That doesn’t look like a valid ZIP Code.",
                inputdaytime: "That doesn’t look like a valid Phone Number.",
                interest_property: "This field is required.",
            },
            submitHandler: function (form: any)
            {
                $('.contact_us_form button[type="submit"]').attr("disabled", `${true}`);
                var service = $(form).find("#interest_property").val();
                var webLocationId = localStorage.getItem('franchiseWebLocationId');
                const smsConsent = $(form).find('#smsConsentText').prop('checked');
                let smsConsentText = "";
                if(smsConsent) {
                    smsConsentText =  "I want to receive SMS updates : Yes";
                }
                else {
                    smsConsentText =  "I want to receive SMS updates : No";
                }
                let comments = $(form).find("#inputComments").val();
                if(comments === "") {
                    comments = smsConsentText;
                }
                else {
                    comments = $(form).find("#inputComments").val() +", " + smsConsentText;
                }

                var requestBody = {
                    WebLocationId: webLocationId || null,
                    email: $(form).find("#inputContactEmail").val(),
                    serviceText: service,
                    firstName: $(form).find("#inputfname").val(),
                    lastName: $(form).find("#inputlname").val(),
                    zipCode: $(form).find("#inputContactZip").val(),
                    DayTimePhoneNumber: ($(form)
                        .find("#inputdaytime")
                        .val() as string)
                        .replace(/[^\d]/g, ""),
                    comments: comments,
                    isLocalized: webLocationId == null ? false : true,
                };
                $.ajax({
                    url: contactFormApi,
                    type: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    crossDomain: true,
                    success: function (data: any)
                    {
                        $(".get_assesment_form").hide();
                        $("#assessment_success").css('position', 'fixed');
                        $("#assessment_success").css('top', '35%');
                        $("#assessment_success").show();
                        clearContactUsForm(form);
                        $('.contact_us_form button[type="submit"]').removeAttr("disabled");
                        // alert("Submitted successfully!" );
                    },
                    error: function (data: any)
                    {
                        $(".get_assesment_form").hide();
                        clearContactUsForm(form);
                        $('.contact_us_form button[type="submit"]').removeAttr("disabled");
                        if (data.status === 200)
                        {
                        } else if (data.responseJSON)
                        {
                            console.log(data.responseJSON.message);
                        }
                        window.location.replace(window.location.origin + "/error");
                    },
                    data: JSON.stringify(requestBody),
                });
                localStorage.removeItem("franchiseWebLocationId");
            },
            errorElement: "div",
            errorPlacement: function (error: any, element: any)
            {
                // Add the `help-block` class to the error element
                error.addClass("help-block invalid-feedback");
                error.insertAfter(element);
            },
            highlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-error")
                    .removeClass("has-success");
            },
            unhighlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-success")
                    .removeClass("has-error");
            },
        });
    }
}
