import { bannerTxtLeftSpace } from "./bannerTxtLeftSpace";
import { $ } from "../third-party-imports";
import { domLoaded } from "../util";


domLoaded(() => {
    
    localStorage.removeItem("franchiseWebLocationId");
    document.querySelectorAll<HTMLAnchorElement>('.js-scroll-section').forEach((link) => {
        link.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();

            let sectionElement = globalThis.document.querySelector(link.getAttribute("href"));
            //sectionElement.scrollIntoView({ behavior: "smooth" });
            $('html, body').animate({
                scrollTop: sectionElement.getBoundingClientRect().top + document.body.scrollTop
            }, 700);

            link.parentElement?.classList.add('active');
            $(link).parent().siblings().removeClass('active');
            return false;
        })
    });

    $('.js-expandible').each(function (this: any)
    {
        var LiN = $(this).find('li').length;
        if (LiN > 3)
        {
            $('li', this).eq(2).nextAll().hide().addClass('js-toggleableListItem');
            $(this).append('<li role="button" class="moreListItems toggleListBtn"><span class="toggleListIcon"></span>Show More</li>');
        }
    });
    
    $('.js-expandible').on('click', '.moreListItems', function (this: any, e: any)
    {
        e.preventDefault();
        if ($(this).hasClass('lessListItems'))
        {
            $(this).removeClass('lessListItems').html('<span class="toggleListIcon"></span> Show More');
        } else
        {
            $(this).addClass('lessListItems').html('<span class="toggleListIcon"></span> Show Less');
        }

        $(this).siblings('li.js-toggleableListItem').toggle();
        e.stopPropagation();
    });


    $(window).scroll(function (this: any)
    {
        if ($(this).scrollTop() ?? 0 > 250)
        {
            $('#back-to-top').fadeIn();
        } else
        {
            $('#back-to-top').fadeOut();
        }
    });

    // scroll body to 0px on click
    $('#back-to-top').click(function ()
    {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $('#back-to-top-article').click(function ()
    {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    /** submenu code start**/
    $('.dropdown-menu a.dropdown-toggle').on('click', function (this: any, e: any)
    {
        if (!$(this).next().hasClass('show'))
        {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        return false;
    });
    /** submenu code end**/

    /**Mobile menu show and hide start */
    $('.mobile-trigger').on('click', function ()
    {
        $('.menu_sec').toggleClass('mobile_menu');
        $('body').addClass('hideScroll')
    });

    $('.close_icon').on('click', function ()
    {
        $('.menu_sec').removeClass('mobile_menu');
        $('body').removeClass('hideScroll')
    })
    /**Mobile menu show and hide end */

    /**Find your nearest office start**/
    $('#your_nearest_office').on('click', function ()
    {
        $('.nearest_office').toggleClass('active').removeAttr('style');
    })
    /**Find your nearest office end**/

    /*Form open on start button click--start */
    $('#start').on('click', function ()
    {
        $('.get_assesment_form').show();
    })
    $('.closeForm span').on('click', function ()
    {
        localStorage.removeItem("franchiseWebLocationId");
        $('.get_assesment_form').hide();
    })
    /*Form open on start button click--end */

    /*Form open on start button click--start */
    $('#assessment_form').on('click', function ()
    {
        $('.fixed_assesment_form').show();
    })
    $('.closeForm span').on('click', function ()
    {
        localStorage.removeItem("franchiseWebLocationId");
        $('.fixed_assesment_form').hide();
    })
    /*Form open on start button click--end */
    /**TopMost blue row closed **/
    $('.close_icn').on('click', function ()
    {
        $('.notify_sec').hide();
        $('body').addClass('notifyHide').removeClass('notify_remove');
    })
})

globalThis.window.addEventListener("load", () => {
   // windSize();
   // $('.loader').hide();
    //banner text spacing from left
    //bannerTxtLeftSpace();    
    if (($(globalThis.window).width()) > 1200) {
        bannerTxtLeftSpace();
    }
});

globalThis.window.addEventListener('resize', () => {
    //windSize();
    bannerTxtLeftSpace();
});

let prevScrollPosition = 0;

globalThis.window.addEventListener("scroll", () => {
    let scrollPosition = $(window).scrollTop() as number;
    let body = globalThis.document.body;

    if (prevScrollPosition > scrollPosition)
    {
        body.classList.add('headerFixed');
        body.classList.remove('notify_remove');
    } 
    else
    {
        body.classList.remove('headerFixed');
    }
    prevScrollPosition = scrollPosition;

    if (prevScrollPosition == 0)
        body.classList.remove('headerFixed');
});


