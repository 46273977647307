
export function attachInputPhoneMask()
{
    var inputs = document.querySelectorAll<HTMLInputElement>('[mask="phone"]');
    for (var i = 0; i < inputs.length; i++)
    {
        var element = inputs[i];
        element.addEventListener("input", function (e)
        {
            let target = (e.target as HTMLInputElement);
            var x = target.value
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            target.value = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        });
    }
}
