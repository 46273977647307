import { getOtherHelpfulBlogs } from "./getOtherHelpfulBlogs";
import { updateFooterLinks } from "./updateFooterLinks";
import { attachNextPrevClickEvent } from "./attachNextPrevClickEvent";
import { $ } from "../../third-party-imports";


export function getAllBlogs(g2CollectionURL: string)
{
    $.ajax({
        type: 'GET',
        url: g2CollectionURL,
        dataType: 'json',
        success: function (data: any)
        {
            var allBlogs = data.response.docs;
            // Update Footer Links
            updateFooterLinks(allBlogs);
            // Get Other Helpful Blogs
            getOtherHelpfulBlogs(allBlogs);
            // attach click event only if links are enabled.
            attachNextPrevClickEvent();
        },
        error: function (e: any)
        {
            console.log(e);
        }
    });
}
