import { clearNewsLetterForm } from "./clearNewsLetterForm";
import { $ } from "../third-party-imports";

export function handleNewsLetterForm()
{
    var newsLetterApi = `https://${window.location.host}/api/newsletter`;    

    /*
     * attach validation event for all the forms based on class name
     */
    var formsList = $(".newsletter_form");
    for (var i = 0; i < formsList.length; i++)
    {
        var formElem = $(formsList[i]);
        formElem.validate({
            rules: {
                inputContactEmail: {
                    required: true,
                    emailValidation: true,
                },
            },
            messages: {
                inputContactEmail: "That doesn’t look like a valid email.",
            },
            submitHandler: function (form: any)
            {
                var firstName = $(form).find("#inputfname").val();
                var lastName = $(form).find("#inputlname").val();
                let fullName;
                if (!firstName && lastName)
                {
                    fullName = lastName;
                } else if (firstName && !lastName)
                {
                    fullName = firstName;
                } else if (firstName && lastName)
                {
                    fullName = firstName + " " + lastName;
                } else
                {
                    fullName = "no-name";
                }
                var requestBody = {
                    name: fullName,
                    email: $(form).find("#inputContactEmail").val(),
                    campaign: {
                        campaignId: "1S",
                    },
                };
                $.post({
                    url: newsLetterApi,
                    dataType: "json",
                    contentType: "application/json",
                    success: function (data: any)
                    {
                        clearNewsLetterForm(form);
                        console.info("Submitted successfully!");
                    },
                    error: function (data: any)
                    {
                        clearNewsLetterForm(form);
                        if (data.status === 200)
                        {
                            console.info("Submitted successfully!");
                        } else if (data.responseJSON)
                        {
                            console.warn(data.responseJSON.message);
                        }
                    },
                    data: JSON.stringify(requestBody),
                });
            },
            errorElement: "div",
            errorPlacement: function (error: any, element: any)
            {
                // Add the `help-block` class to the error element
                error.addClass("help-block invalid-feedback");
                error.insertAfter(element);
            },
            highlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-error")
                    .removeClass("has-success");
            },
            unhighlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-success")
                    .removeClass("has-error");
            },
        });
    }
}
