import { stripPageNumber } from "./stripPageNumber";
import { stripTrailingSlash } from "./stripTrailingSlash";

// Strip URL
export const stripURL = (pageURL: string) => {
    var pageURL = pageURL;
    pageURL = stripTrailingSlash(pageURL);
    pageURL = stripPageNumber(pageURL);
    return pageURL;
};
