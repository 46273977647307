import { handleClickAndEnter } from "./handleClickAndEnter";
import { handleLocateOnBanner } from "./handleLocateOnBanner";
import { handleLocateOnBlogs } from "./handleLocateOnBlogs";
import { handleLocateOnHeader } from "./handleLocateOnHeader";
import { mobileHandleLocateOnHeader } from "./mobileHandleLocateOnHeader";
import { mobileUpdatePostalCodeOnHeader } from "./mobileUpdatePostalCodeOnHeader";
import { updatePostalCodeOnHeader } from "./updatePostalCodeOnHeader";
import { updateSearchTermOnBanner } from "./updateSearchTermOnBanner";
import { updateSearchTermOnBlog } from "./updateSearchTermOnBlog";
import { $ } from "../third-party-imports";


$(function() {    
    $('.invalid-feedback').css('visibility', 'hidden');
    updatePostalCodeOnHeader();
    handleLocateOnHeader();
    mobileUpdatePostalCodeOnHeader();
    mobileHandleLocateOnHeader();
    updateSearchTermOnBanner();
	updateSearchTermOnBlog();
    handleLocateOnBanner();
	handleLocateOnBlogs();

    /**
     * Handle Enter key press on input
     * 1. Header - Web 
     * 2. Header - Mobile
     * 3. Banner Image - Web & Mobile
     */
    $(document).on("keyup", function(event: any) {
        var inputElement;
        var validationErrorElement;
        if ($('.locateForm input').is(":focus") && event.key == "Enter") {
            inputElement = $('.locateForm').find('input');
            validationErrorElement = $('.locateForm .invalid-feedback');
            handleClickAndEnter(inputElement, validationErrorElement);
        } else if($('.navWrapper .propertForm input').is(":focus") && event.key == "Enter") {
            inputElement = $('.navWrapper .propertForm').find('input');
            validationErrorElement = $('.navWrapper .propertForm .invalid-feedback');
            handleClickAndEnter(inputElement, validationErrorElement);
        } else if($('.bannerHeroContainer .propertForm input').is(":focus") && event.key == "Enter") {
            inputElement = $('.bannerHeroContainer .propertForm').find('input');
            validationErrorElement = $('.bannerHeroContainer .propertForm .invalid-feedback');
            handleClickAndEnter(inputElement, validationErrorElement);
        } else if($('.newsletter_sec .propertForm input').is(":focus") && event.key == "Enter") {
            inputElement = $('.newsletter_sec .propertForm').find('input');
            validationErrorElement = $('.newsletter_sec .propertForm .invalid-feedback');
            handleClickAndEnter(inputElement, validationErrorElement);
        }
    });

});