

import { $ } from "../../third-party-imports";

export function attachNextPrevClickEvent()
{
    var prevIndex = parseInt(localStorage.getItem('blog_article_prev_index') ?? "0");
    $('.linkText.enable-back-link').click(function (e: any)
    {
        e.preventDefault();
        if (prevIndex > 0)
        {
            localStorage.setItem('blog_article_prev_index', `${prevIndex - 1}`);
        }
        window.location.href = e.target.getAttribute('href');
    });
    $('.linkText.enable-next-link').click(function (e: any)
    {
        e.preventDefault();
        localStorage.setItem('blog_article_prev_index', `${prevIndex + 1}`);
        window.location.href = e.target.getAttribute('href');
    });
}
