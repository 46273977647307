import { attachInputPhoneMask } from "./attachInputPhoneMask";
import { handleContactUsForm } from "./handleContactUsForm";
import { handleNewsLetterForm } from "./handleNewsLetterForm";
import { init } from "./init";
import { locateAnOffice } from "./locateAnOffice";
import { setUrlVarsInLocalStorage } from "./setUrlVarsInLocalStorage";
import { domLoaded } from '../util/domLoaded';
import { youtubeInit } from './youtubeInit';
import { $ } from "../third-party-imports";
import { contactBuilderForm } from "./contactBuilderForm";

domLoaded(() => {
  init();
  youtubeInit();
  attachInputPhoneMask();
  handleNewsLetterForm();
  handleContactUsForm();
  contactBuilderForm();
  setUrlVarsInLocalStorage();
  
  if ($("#locateOffice").length) 
    locateAnOffice();
});
