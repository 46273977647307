import { clearContactUsForm } from "./clearContactUsForm";
import { $ } from "../third-party-imports";

export function contactBuilderForm()
{ 
    const contactFormApi = `${window.env.contactUsBaseEndpoint}?apikey=${window.env.apiKey}`;
    const formsList = $(".contact_form");
    
    for (var i = 0; i < formsList.length; i++)
    {
        const formElem = $(formsList[i]);
        formElem.validate({
            rules: {
                inputEmailAddress: {
                    required: true,
                    emailValidation: true,
                },
                inputZipCode: {
                    required: true,
                    zipValidation: true,
                },
                inputPhone: {
                    required: true,
                    phoneValidation: true,
                },
                service_selection: {                    
                    required: true,
                }
            },
            messages: {
                inputEmailAddress: "That doesn’t look like a valid email.",
                inputZipCode: "That doesn’t look like a valid ZIP Code.",
                inputPhone: "That doesn’t look like a valid Phone Number.",
                service_selection: "This field is required.",
            },
            submitHandler: function (form: any)
            {
                $('.contact_form button[type="submit"]').attr("disabled", `${true}`);
                var service = $(form).find("#service_selection").val();
                var note =  $(form).find("#additional_notes").val();
                var webLocationId = localStorage.getItem('franchiseWebLocationId');
                var comment  =  $(form).find("#inputComment").val();
                var combineMsg = comment && note ?  comment + ' || ' +' Note : ' + note : comment ? comment : note ? 'Note : ' + note:'' ;
                var requestBody = {
                    WebLocationId: webLocationId || null,
                    email: $(form).find("#inputEmailAddress").val(),
                    serviceText: service,
                    firstName: $(form).find("#inputfirstname").val(),
                    lastName: $(form).find("#inputlastname").val(),
                    zipCode: $(form).find("#inputZipCode").val(),
                    DayTimePhoneNumber: ($(form)
                        .find("#inputPhone")
                        .val() as string)
                        .replace(/[^\d]/g, ""),
                    comments:combineMsg ,
                    isLocalized: webLocationId == null ? false : true,
                };
                $.ajax({
                    url: contactFormApi,
                    type: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    crossDomain: true,
                    success: function (data: any)
                    {
                        $(".get_assesment_form").hide();
                        $("#assessment_success").css('position', 'fixed');
                        $("#assessment_success").css('top', '35%');
                        $("#assessment_success").show();
                        clearContactUsForm(form);
                        $('.contact_form button[type="submit"]').removeAttr("disabled");
                        // alert("Submitted successfully!" );
                    },
                    error: function (data: any)
                    {
                        $(".get_assesment_form").hide();
                        clearContactUsForm(form);
                        $('.contact_form button[type="submit"]').removeAttr("disabled");
                        if (data.status === 200)
                        {
                        } else if (data.responseJSON)
                        {
                            console.log(data.responseJSON.message);
                        }
                        window.location.replace(window.location.origin + "/error");
                    },
                    data: JSON.stringify(requestBody),
                });
                localStorage.removeItem("franchiseWebLocationId");
            },
            errorElement: "div",
            errorPlacement: function (error: any, element: any)
            {
                // Add the `help-block` class to the error element
                error.addClass("help-block invalid-feedback");
                error.insertAfter(element);
            },
            highlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-error")
                    .removeClass("has-success");
            },
            unhighlight: function (element: any, errorClass: any, validClass: any)
            {
                $(element)
                    .parents(".form-group")
                    .addClass("has-success")
                    .removeClass("has-error");
            },
        });
    }

    $('.contactFormSection .contact_form input[name="inputZipCode"]').keypress(function(this:any,e:any){
            if (this.value === "0000" && e.which == 48){
                return false;
            }
     })
}
