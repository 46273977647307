import { handleClickAndEnter } from "./handleClickAndEnter";
import { $ } from "../third-party-imports";


export function handleLocateOnBlogs()
{
    $(".newsletter_sec .propertForm button").on("click", function ()
    {
        var inputElement = $('.newsletter_sec .propertForm').find('input');
        var validationErrorElement = $('.newsletter_sec .propertForm .invalid-feedback');
        handleClickAndEnter(inputElement, validationErrorElement);
    });
}
