import { domLoaded } from "../util/domLoaded";

domLoaded(async () => {

    const mapContainer = document.getElementById("map");
    if (mapContainer)
    { 
        (await import("./util/mapInit")).mapInit(mapContainer);
    }
})

