import { initBlogArticlePage } from "./initBlogArticlePage";
import { $ } from "../../third-party-imports";

export function initBlogArticle() 
{
    initBlogArticlePage();

    $('#backToBlogIndexLink').on("click", function ()
    {
        var returnlink = localStorage.getItem('current_context_url');
        if (returnlink === "" || returnlink === null)
        {
            window.location.replace(`${window.location.origin}/expert-tips`);
        }
        else 
        {
            window.location.replace(returnlink);
        }
    });
};