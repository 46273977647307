

import { $ } from "../../third-party-imports";

export function attachHelpLinkClickEvent()
{
    $('.helpLink').click(function (e: any)
    {
        e.preventDefault();
        var articleIndex = e.target.getAttribute('data-index');
        var currentPrevIndex = localStorage.getItem('blog_article_prev_index') || "1";
        const startingIndex = (parseInt(currentPrevIndex) + parseInt(articleIndex));
        let index = startingIndex === 0 ? 0 : startingIndex - 1;
        localStorage.setItem('blog_article_prev_index', `${index}`);
        window.location.href = e.target.getAttribute('href');
    });
}
