import { $ } from "./third-party-imports";
// import "jquery-validation";

$(function(){

    $.validator.setDefaults({
        onkeyup: function (this: any, element: any, event: any) {
            if (event.which === 9 && this.elementValue(element) === "") {
              return;
            } else {
              this.element(element);
            }
        },
        submitHandler: function () {
            $(".get_assesment_form").hide();
            alert( "submitted form successfully!" );
        },
        errorElement: "div",
        errorPlacement: function (error: any, element: any) {
            // Add the `help-block` class to the error element
            error.addClass("help-block invalid-feedback");
            error.insertAfter(element);
        },
        highlight: function ( element: any, errorClass: any, validClass: any ) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element: any, errorClass: any, validClass: any) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        }
    });
  
    $("#assesment_form").validate({
        rules: {
            inputAssesmentEmail: {
                required: true,
                email: true
            },
            inputAssesmentZip: {
                required: true,
                zipCodeValidation: true // hook in custom zip code validation
            }
        },
        messages: {
            inputAssesmentEmail: "That doesn’t look like a valid email.",
            inputAssesmentZip: "That doesn’t look like a valid ZIP Code.",
        },  
    });

    $("#contact_us_form").validate({
        rules: {
            inputContactEmail: {
                required: true,
                email: true
            },
            inputContactZip: {
                required: true,
                zipContactValidation: true // hook in custom zip code validation
            }
        },
        messages: {
            inputContactEmail: "That doesn’t look like a valid email.",
            inputContactZip: "That doesn’t look like a valid ZIP Code.",
        },  
    });

    $("#download_info_form").validate({
        rules: {
            inputInfoEmail: {
                required: true,
                email: true
            },
            inputInfoName: {
                required: true
            },
            inputInfoPhn: {
                required: true
            }
        },
        messages: {
            inputInfoEmail: "That doesn’t look like a valid email.",
            inputInfoName: "That doesn’t look like a valid Full Name.",
            inputInfoPhn: "That doesn’t look like a valid Phone Number.",
        },  
    });

    $("#access_account_form").validate( {
        rules: {
            email: {
                required: true,
                email: true,
            }
        },
        messages: {
            email: "That doesn’t look like a valid email."
        },
        
    });
    
  
    $.validator.addMethod("zipCodeValidation", function() {
      var zipCode = $('input#inputAssesmentZip').val() as string;
      return (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(zipCode); // returns boolean
    }, "Please enter a valid US ZIP code (use a hyphen if 9 digits).");

    $.validator.addMethod("zipContactValidation", function() {
        var zipContactCode = $('input#inputContactZip').val() as string;
        return (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(zipContactCode); // returns boolean
    }, "Please enter a valid US ZIP code (use a hyphen if 9 digits).");



});