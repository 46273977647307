import { $ } from "../third-party-imports";

/**
 * Handle locate button Click and Enter button pressed on input box
 */

export function handleClickAndEnter(inputElement: any, validationErrorElement: any)
{
    var postalCode = $(inputElement).val() as string;
    var zipRegex = new RegExp(/(^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$)/);

    if (postalCode !== '' && zipRegex.test(postalCode))
    {
        localStorage.setItem('searchTerm', postalCode);
        window.location.href = "https://" + window.location.host + "/map?search=" + postalCode;
        $('.invalid-feedback').css('visibility', 'hidden');
    } else if (postalCode === '')
    {
        $(validationErrorElement).css('visibility', 'visible');
        $(validationErrorElement).html('Zip/Postal Code cannot be empty');
    } else if (!zipRegex.test(postalCode))
    {
        $(validationErrorElement).css('visibility', 'visible');
        $(validationErrorElement).html("Please enter a valid US / CA postal code");
    }
    
    event.preventDefault();
    event.stopPropagation();
}
