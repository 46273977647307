
export function setUrlVarsInLocalStorage()
{
    var hash;
    var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");
    for (var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split("=");
        window.localStorage.setItem(hash[0], hash[1]);
        if (hash[0].includes("phone"))
        {
            var x = hash[1].replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            hash[1] = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
            document.querySelector(".emergeny_call .call_icon").innerHTML =
                hash[1] + "<span>24/7 Emergency Services</span>";
        }
    }
}
