import { $ } from "../third-party-imports";

export function init()
{
    /*Form open on start button click--start */
    $("#start_assesment, #start_assesment_mobile").on("click", function (this: any, e: any)
    {
        e.preventDefault();
        var elm = $(this);
        var offset = elm.offset();
        if (window.matchMedia("(max-width: 767px)").matches)
        {
            $(".get_assesment_form").css("top", 0);
            $(".get_assesment_form").css("bottom", "auto");
        } else
        {
            $(".get_assesment_form").css("top", offset.top);
            $(".get_assesment_form").css("bottom", "auto");
        }
        $($(".get_assesment_form").find('.row')[1]).html('');
        $($(".get_assesment_form").find('.row')[1]).html(
            '<div class="col-lg-6 col-md-6">' +
            '<h3 class="blue-regular-text formHeading">Find out how much you should charge for rent:</h3>' +
            '</div>'
        );
        $(".get_assesment_form").show();
    });
    // bottom assessment button click
    $("#start_assesment_bottom").on("click", function (this: any)
    {
        var elm = $(this);
        var offset = elm.offset();
        var height = $(".get_assesment_form").outerHeight() + 20;
        if (window.matchMedia("(max-width: 767px)").matches)
        {
            $(".get_assesment_form").css("top", 0);
            $(".get_assesment_form").css("bottom", "auto");
        } else
        {
            $(".get_assesment_form").css("top", offset.top - height);
            $(".get_assesment_form").css("bottom", "auto");
        }
        $($(".get_assesment_form").find('.row')[1]).html('');
        $($(".get_assesment_form").find('.row')[1]).html(
            '<div class="col-lg-6 col-md-6">' +
            '<h3 class="blue-regular-text formHeading">Find out how much you should charge for rent:</h3>' +
            '</div>'
        );
        $(".get_assesment_form").show();
    });

    $.validator.addMethod(
        "zipValidation",
        function (this: any, value: any, element: any)
        {
            var re = new RegExp(
                /(^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$)/
            );
            return this.optional(element) || re.test(value);
        },
        "Please enter a valid US / Canada ZIP code."
    );

    $.validator.addMethod(
        "emailValidation",
        function (this: any, value: any, element: any)
        {
            var re = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            return this.optional(element) || re.test(value);
        },
        "Please enter a valid email."
    );

    $.validator.addMethod(
        "phoneValidation",
        function (this: any, value: any, element:  any)
        {
            var re = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
            return this.optional(element) || re.test(value);
        },
        "Please enter a valid phone number."
    );    

    $("#assessment_success button").on("click", function (this: any)
    {
        $("#assessment_success").hide();
    });

    $('#assessment_success .closeForm span').on('click', function ()
    {
        $('#assessment_success').hide();
    });
}
