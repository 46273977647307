
import { $ } from '../third-party-imports';
import { domLoaded } from '../util/domLoaded';

function OwlCarousel() 
{
    const selector = '.owl-carousel';

    if (document.querySelector(selector) != null)
    {
        // @ts-ignore
        // (await import("owl.carousel/dist/owl.carousel"));
        let carouselScript = document.createElement('script');
        carouselScript.setAttribute('type', 'text/javascript');
        carouselScript.id = "carousel-script";
        carouselScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js');
        document.body.appendChild(carouselScript);

    }
}
domLoaded(() => {
 OwlCarousel();
});

 window.onload = function() 
{
    const selector = '.owl-carousel';

    if (document.querySelector(selector) != null)
    {
        // @ts-ignore
        // (await import("owl.carousel/dist/owl.carousel"));

        const owl = $('.owl-carousel');
        owl.owlCarousel({
            margin: 10,
            nav: false,
            lazyLoad : true,
            loop: true,
            autoplay: true,
            responsive: {
                0: {
                    items: 1
                },
                520: {
                    items: 1,
                    stagePadding: 0
                },
                768: {
                    items: 1,
                    stagePadding: 100
                },
                1000: {
                    items: 1,
                    stagePadding: 300
                },
                1700: {
                    items: 1,
                    stagePadding: 600
                }
            }
        })
    }
}