import { filterBlogFromList } from "../../blogarticle/util/filterBlogFromList";
import { attachHelpLinkClickEvent } from "../../blogarticle/util/attachHelpLinkClickEvent";
import { envConfig } from "../../envConfig";

import { $ } from "../../third-party-imports";

export function getOtherHelpfulBlogs(allBlogs: any[])
{
    var blogArticles: any[] = [];
    var counter = 0;
    allBlogs.forEach((blog, index) => {
        if (filterBlogFromList(blog) !== true)
        {
            if (counter < 4)
            {
                counter++;
                var imgSRC = blog.custom_s_article_summary_Image_url;
                blogArticles.push('<div class="col-md-6">' +
                    '<div class="media blogFeaturedCol">' +
                    '<img class="mr-3 blogFeaturedImg" alt="' + blog.custom_t_content_summary_Image_url_alt + '" src=' + imgSRC + '>' +
                    '<div class="media-body">' +
                    `<a class="redLinkText" data-index="${index}" href=${blog.custom_s_local_url}>` +
                    blog.custom_s_heading +
                    '</a>' +
                    '</div>' +
                    '</div>' +
                    '</div>'
                );
            }
        }
    });
    $('.otherStoriesSection .row:first').append(blogArticles);
    attachHelpLinkClickEvent();

    if (counter < 4)
    {
        var g2Collection = envConfig.g2SearchBaseUrl;
        var g2Brand = "?q=custom_s_brand:rpm";
        var otherParams = "&start=0&wt=json&indent=true&sort=custom_dt_created desc";
        var filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
        var g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_status:Active' + otherParams + filteredParams;

        $.ajax({
            type: 'GET',
            url: g2CollectionURL,
            dataType: 'json',
            success: function (data: any)
            {
                let allBlogs: any[] = data.response.docs;
                allBlogs.forEach((blog, index) => 
                {
                    if (filterBlogFromList(blog) !== true)
                    {
                        if (counter < 4)
                        {
                            counter++;
                            var imgSRC = blog.custom_s_article_summary_Image_url ? blog.custom_s_article_summary_Image_url : '';
                            blogArticles.push('<div class="col-md-6">' +
                                '<div class="media blogFeaturedCol">' +
                                '<img class="mr-3 blogFeaturedImg" alt="' + blog.custom_t_content_summary_Image_url_alt + '" src=' + imgSRC + '>' +
                                '<div class="media-body">' +
                                `<a class="redLinkText" data-index="${index}" href=${blog.custom_s_local_url}>` +
                                blog.custom_s_heading +
                                '</a>' +
                                '</div>' +
                                '</div>' +
                                '</div>'
                            );
                        }
                    }
                });
                
                $('.otherStoriesSection .row:first').append(blogArticles);
                attachHelpLinkClickEvent();
            },
            error: (e: any) => { console.log(e); }
        });
    }
}
