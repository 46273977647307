import { getAllBlogs } from "../../blog/util/getAllBlogs";
import { envConfig } from "../../envConfig";

export function searchG2QueryParams(key: any, value: any)
{
    var g2Collection = envConfig.g2SearchBaseUrl;
    let linkHref = window.location.href.toLowerCase();
    let countryCode = linkHref.split('/')[3] === "ca" ? "ca" : "us";
    let brandQuery = "";
    const localDBA = linkHref.split("/")[4];
    if (linkHref.split("/")[5] != undefined) {
        if (linkHref.split("/")[5].indexOf("expert-tips") != -1) {
            brandQuery = `&fq=custom_s_local_dba:(${​localDBA} OR none)`;
        }
    }
    else{
        brandQuery = `&fq=custom_s_local_dba:none`;
    }
    var g2Brand = `?q=custom_s_brand:rpm${brandQuery}&fq=custom_s_country:${countryCode}`;
    var start = 0;
    //var prevArticleIndex = parseInt(localStorage.getItem('blog_article_prev_index') || "0");
    //var totalArticleCounts = parseInt(localStorage.getItem('blog_total_count') || "0");
    // If we found above variables in localStorage then it will be considered as previous search context
    // if (prevArticleIndex && totalArticleCounts) {
    // if (totalArticleCounts > 5 && (prevArticleIndex + 2 >= totalArticleCounts)) {
    // start = totalArticleCounts - 5;
    // } else {
    // start = prevArticleIndex;
    // }
    // }
   
    var otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
    var filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
    var g2CollectionURL;
    if (key && value)
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_status:Active' + otherParams + filteredParams;
    } else
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_status:Active' + otherParams + filteredParams;
    }
    // Get All Blogs
    getAllBlogs(g2CollectionURL);
}
