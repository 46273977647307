/**
 * Get the Search term from query string => Read a page's GET URL variables and return them as an associative array
 */

export function getUrlVars()
{
    let vars: {[key: string]: string} = {};
    let hash: string[];
    let hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

    for (let i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split("=");
        vars[hash[0]] = hash[1];
    }
    return vars;
}
